export enum Messages {
  UnexpectedError = 'Unexpected Error Occurred',
  UserNotFound = 'User Not Found',
  AuthenticateUserInfo = 'Authenticated user info',
  Unauthorized = 'Unauthorized',
  SignedByNameLength = 'The name must be at least four characters',
  StudentNameRequired = 'Student full name is required',
  StudentNameExists = 'Student with this name already exists',
  StudentEmailInvalid = 'Student email is invalid',
  StudentEmailRequired = 'Student email is required',
  StudentNotFound = 'Student not found',
  CustomerNameRequired = 'Customer name is required',
  CustomerEmailInvalid = 'Customer email is invalid',
  CustomerEmailRequired = 'Customer email is required',
  AgeRequired = 'Age is required',
  InvalidEmail = 'Email is invalid',
  InvalidCredentials = 'Invalid password or sign-in email',
  Forbidden = 'Forbidden',
  BadRequest = 'Bad request',
  FailedGetInvoice = 'Failed to get invoice',
  FailedProcessInvoice = 'Failed to process invoice',
  FailedUpdateInvoice = 'Failed to update invoice',
  CustomerForStudentNotFound = 'Customer with this email is not registered',
  StudentIsNotAssociatedWithCustomer = 'Customer with this email does not have one of specified student',
  FailedProcessPayment = 'Failed to process payment',
  FailedGetActivities = 'Failed get activities',
  ActivityDeleted = 'Activity deleted',
  FailedProcessEfacitySession = 'Failed to process Efacity Session: insufficient permissions',
  FailedProcessActivity = 'Failed to process Activity: insufficient permissions.',
  PaymentSuccess = 'Order processed',
  PaymentSuccessButPhone = 'Order processed but failed to send SMS notification.',
  PaymentSuccessButEmailReceipt = 'The order was processed, but the email receipt could not be sent.',
  LastRegistrationInInvoiceDeleted = 'Last registration was deleted from invoice.',
  StripeInsufficientFunds = 'Your card has insufficient funds.',
  StripeExpiredCard = 'Your card has expired.',
  StripeAuthenticationRequired = 'To process this payment the bank is required authentication',
  Stripe3DRedirect = 'Redirect to Bank 3DS authentication page...',
  Stripe3DFailed = 'Your bank did not confirm this payment.\nPlease try to use another card.',
  ConfirmPayment = 'Confirm payment...',
  EmailRequired = 'Email is required',
  ShouldBeLonger2Symbols = 'Should be longer than 2 symbols',
  SelectGrade = 'Select grade',
  SelectYear = 'Select year',
  SelectMonth = 'Select month',
  SelectDay = 'Select day',
  UserWithEmailExists = 'User with such email already registered',
  CannotUpdateUser = 'Cannot update user',
  CannotDeleteUser = 'Cannot delete user',
  UserExistsShouldAddTeacher = 'User with such email already exists. Would you like to add the user as a Teacher to your school?',
  FailedAddTeacher = 'Failed add Instructor',
  FailedPasswordEmailDueServerContactUs = 'Failed to sent email with new password to new instructor due email server error. Contact us via info@efacity.com',
  FailedGetSessionsRegisterOn = 'Failed to get sessions to register on',
  ToStartNeedRegistrations = 'To start registration you need select at least one session',
  EnrichmentSessionOnly = 'Registration allowed only on Enrichment session',
  UserAlreadyRegisteredSession = 'User with such email already registered to this session',
  StudentRegistered = 'Student registered to session',
  FailedRegisteredOnSession = 'Failed to register on session',
  RegisteredOnSessionButEmail = 'Student(s) registered to session, but we failed to send notification(s)',
  StudentRegisteredOnSessionButPhone = 'Student registered to session, but we failed to send SMS notification.',
  StudentRegisteredOnSessionButEmail = 'Student registered to session, but we failed to send email notification.',
  StudentRegisteredOnSessionButEmailAndPhone = 'Student registered to session, but we failed to send email and SMS notifications.',
  FailedDeleteRegistrationReload = 'Failed to delete registration. Please, reload page',
  FailedSwitchOptionRegistrationReload = 'Failed to switch Additional Option in invoice. Please, reload page',
  AdminUserLoggedOutOnRegistration = 'Admin user logged out on start of Registration',
  SessionAddedToShoppingCart = 'Selected session added to ShoppingCart',
  FailedAddSessionToShoppingCart = 'Failed add session to ShoppingCart',
  FailedAddItemToShoppingCart = 'Failed add item to ShoppingCart',
  KitAddedToShoppingCart = 'Selected Kit added to ShoppingCart',
  KitNoQuantity = 'Kit not available',
  FailedAddKitToShoppingCart = 'Failed add Kiit to ShoppingCart',
  UnableFindSession = 'Unable to find Efacity Session',
  FailedGetInstructors = 'Failed to get instructors list',
  FailedGetInstructor = 'Failed to get instructor',
  InstructorCreated = 'Instructor created',
  InstructorActivated = 'Instructor activated',
  FailedActivateInstructor = 'Failed to activate instructor',
  FailedCreateInstructor = 'Failed to create instructor',
  FailedUpdateInstructor = 'Failed to update instructor',
  FailedDeleteInstructor = 'Failed to delete instructor',
  InstructorDeleted = 'Instructor deleted',
  FailedToGetStudents = 'Failed to get students information',
  FailedGetUsers = 'Failed to get users',
  FailedToGetUser = 'Failed to get user information',
  FailedToGetStudent = 'Failed to get student information',
  FailedToAddStudent = 'Failed to add student',
  FailedGetStudent = 'Failed get student info',
  FailedToUpdateStudent = 'Failed to update student',
  FailedToUpdateUser = 'Failed to update user',
  FailedToCreateUser = 'Failed to create user',
  UserCreatedSuccessfully = 'User created',
  UserUpdatedSuccessfully = 'User updated',
  UserDeletedSuccessfully = 'User deleted',
  FailedGetActivityInfo = 'Failed to get activity info',
  FailedGetActivitiesInfo = 'Failed to get activities info',
  FailedGetLocation = 'Failed to get the location',
  FailedGetSession = 'Failed to get session',
  FailedGetSessionsInfo = 'Failed to get sessions info',
  FailedExportSessions = 'Failed to export sessions list',
  FailedGetSessions = 'Failed get sessions list',
  ActivityNotFound = 'Activity not found',
  ActivityCreated = 'Activity created',
  ActivityUpdated = 'Activity updated',
  ActivityNameNotUnique = 'This name is already in use, try another one',
  ActivityNameRequired = 'Activity name is required',
  ActivityShared = 'Activity shared',
  FailedShareActivity = 'Failed share activity',
  CannotUploadImage = 'Cannot upload image',
  CannotUploadMedia = 'Cannot upload media',
  ImageSizeIsTooBig = 'Image size is too big',
  FileSizeIsTooBig = 'File size is too big',
  NoItemsInShoppingCart = 'No Items in Shopping Cart',
  FailedGetDiscounts = 'Failed to get discounts list',
  FailedCreateDiscount = 'Failed to create Discount',
  FailedUpdateDiscount = 'Failed to update Discount',
  FailedProcessDiscount = 'Failed to process Discount: insufficient permissions',
  FailedGetLoginAudit = 'Failed to get login audit data',
  FailedGetMessages = 'Failed to get Messages',
  FailedGetPayments = 'Failed to get Payments',
  MessageNotFound = 'Message not found',
  FailedResendMessage = 'Failed to resend Message',
  FailedGetKits = 'Failed to get Kits',
  FailedGetKit = 'Failed to get Kit',
  FailedToCreateKit = 'Failed to create Kit',
  FailedToUpdateKit = 'Failed to update Kit',
  KitDeleted = 'Kit deleted',
  FailedToDeleteKit = 'Failed to delete Kit',
  KitNotFound = 'Kit not found',
  FailedGetAdditionalOptions = 'Failed to get Additional Options',
  FailedProcessAdditionalOptionDuePermissions = 'Failed to process Additional Option: insufficient permissions',
  FailedToggleAdditionalOption = 'Failed toggle additional option',
  FailedAddAdditionalOption = 'Failed to add Additional Option',
  FailedUpdateAdditionalOption = 'Failed to update Additional Option',
  AdditionalOptionDeleted = 'Additional Option deleted',
  FailedDeleteAdditionalOption = 'Failed to delete Additional Option',
  DescriptionRequired = 'Description is required',
  QuantityRequired = 'Quantity is required',
  QuantityShouldBePositive = 'Quantity should be positive',
  DiscountDeleted = 'Discount deleted',
  FailedDeleteDiscount = 'Failed to delete Discount',
  FailedGetDiscount = 'Failed to get Discount info',
  CodeRequired = 'Code is required',
  ExpiredOnRequired = 'Expired on Date is required',
  CustomerDescriptionRequired = 'Description for Customer is required',
  UnknownDiscountType = 'Unknown Discount Type',
  UnknownDiscountClass = 'Unknown Discount Class',
  StringRequired = 'String value is required',
  DiscountAmountRequired = 'Discount Amount is required',
  DiscountPercentageRequired = 'Discount Percentage is required',
  WrongCurrencyCode = 'Wrong Currency Code',
  SelectCurrency = 'SelectCurrency',
  FailedVerifyDiscount = 'Failed to verify Discount Code',
  InPersonRoomRequired = 'Location address is required for In-Person',
  ActivityRequired = 'Activity is required',
  StatusRequired = 'Status is required',
  NameRequired = 'Name is required',
  SignatureRequired = 'Signature is required',
  TitleRequired = 'Title is required',
  JobTypeRequired = 'Type is required',
  JobRateRequired = 'Rate is required',
  MinimumRangeRequired = 'Minimum Range is required',
  NonNegativeExpected = 'Non negative value expected',
  JobScheduleRequired = 'Schedule is required',
  JobOpenPositionsRequired = 'Open Positions is required',
  JobPayByRequired = 'Pay by is required',
  StudentIdRequired = 'Student ID is required',
  StudentIdLength = 'Must be 3-20 characters',
  PhoneRequired = 'Phone is required',
  CenterCreatedSuccessfully = 'Center created',
  CenterCreatedButEmail = 'Center created, but failed send email to administrator.',
  CenterUpdated = 'Center updated',
  CenterUpdatedButAdminNotifications = 'Center updated but failed send administrator notifications',
  FieldRequired = 'is required',
  TimeZoneRequired = 'Timezone is required',
  DefaultAddressRequired = 'Default address is required',
  SessionCancelledSuccessfully = 'Session cancelled',
  FailedRestoreSession = 'Failed to restore session',
  FailedToCancelSession = 'Failed to cancel session',
  FailedCancelRegistration = 'Failed to cancel registration',
  FailedGetRegistrations = 'Failed to get registrations list',
  RegistrationCancelled = 'Registration cancelled',
  UniqueTagError = 'Tags should be unique',
  DefiniteTagsAreNotUnique = 'The following tags are not unique:',
  FailedGetTags = 'Failed get Tags',
  TagNotFound = 'Tag not found',
  FailedGetTag = 'Failed get Tag',
  FailedCreateTag = 'Failed create Tag',
  FailedUpdateTag = 'Failed update Tag',
  TagUpdated = 'Tag updated',
  FailedTaggingSessions = 'Failed to tagging sessions with SessionPeriod',
  FailedProcessTag = 'Failed to process Tag: insufficient permissions',
  TagNameIsNotUniqueError = 'Tag name should be unique',
  TagValidFromRequired = 'Valid From is required for SessionPeriod',
  IncorrectDate = 'Incorrect date',
  TagValidToRequired = 'Valid To is required for SessionPeriod',
  TagNameError = 'Tag name is required',
  FailedGetAttendance = 'Failed get Attendance',
  FailedSignAttendance = 'Failed to sign attendance',
  FailedExportAttendance = 'Failed to export Attendance',
  AttendanceStatusRequired = 'Attendance status is required',
  NonAcceptableAttendanceStatus = 'This attendance status is not acceptable',
  CannotUpdateAttendanceStatus = 'Cannot update attendance status',
  InvalidAttendanceStartDate = 'Attendance start date is invalid',
  InvalidAttendanceEndDate = 'Attendance end date is invalid',
  FromDateShouldBeBeforeToDate = 'Start date should be before end date',
  FromDateIsRequiredWhenToDateSpecified = 'Start date is required if end date is specified',
  PasswordIsTooShort = 'Password should be at least 8 characters long',
  PasswordShouldHaveDigitsAndLetters = 'Password must have at least 8 characters and include both digits and letters',
  PasswordRequired = 'Password is required',
  PasswordIsWeak = 'Password is too weak',
  ToDateIsRequiredWhenFromDateSpecified = 'End date is required if start date is specified',
  PerPageLimitIsTooBig = 'Per page limit should not be more than ',
  TooManyAttempts = 'Your account has been locked due to too many failed login attempts',
  CannotLoadRecaptcha = 'Cannot load recaptcha',
  MissingPassword = 'Password is missing. (Did you mean to Sign in with Google?)',
  SchoolAdministratorsRequired = 'You need to add School administrator',
  SchoolAndAdminCreated = 'Successfully created school and attached admin',
  FailedCreateSchool = 'Failed to create school',
  ResetPasswordEmailSent = 'Instructions to reset password sent to your email.',
  PasswordLinkExpired = 'Your reset password link expired.',
  PasswordResetSuccess = 'Password reset successfully. You can now sign in.',
  EmailVerificationSentSes = 'We sent verification message to your email. Please check your inbox or spam folder and follow instructions.',
  FailedSendEmailVerificationSes = 'Failed to send verification email.',
  EmailAlreadyVerifiedSes = 'Email already verified.',
  EmailValidationSent = 'We sent instructions to validate your email. Please, follow it to continue.',
  FailedSendValidationEmail = 'Failed to send validation email',
  CodeSentOnPhone = 'Validation code sent on phone',
  FailedSendValidationCodeViaEmail = 'Failed to send validation code via email',
  FailedSendValidationCodeViaPhone = 'Failed to send validation code via phone',
  FailedSendValidationCodeViaEmailAndPhone = 'Failed to send validation code via email and phone',
  TestEmailSent = 'Test email sent successfully.',
  FailedTestEmail = 'Failed to send test email.',
  FailedValidationEmail = 'Failed to send validation email.',
  EmailNotValidatedInstructionsSent = 'Your account is not validated. We sent validation instructions your email.',
  EmailValidationFailed = 'Email validation failed.',
  EmailValidationWrongKey = 'Email validation failed. Wrong validation key.',
  EmailValidationNoKey = 'Email validation failed. No validation key.',
  EmailValidationSuccessful = 'Email validated successfully.',
  ValidationNoKey = 'Validation failed. No validation key.',
  ValidationSuccess = 'Validation success.',
  ValidationWrongKey = 'Validation failed. Wrong validation key.',
  SendVisitorInfoSuccess = 'We received your contact information and will get in touch',
  CannotSendVisitorInfo = 'Cannot send your contact information. Issue has been reported. Please try again later.',
  SessionCancellationReasonRequired = 'Session cancellation reason is required',
  SessionPublishedButEmailsNotSent = 'Session was published but email with calendar was not sent',
  SchoolFeeShouldBeNumeric = 'School fee should be a number',
  SchoolFeeIsRequired = 'School fee is required',
  SchoolFeeInvalidRange = 'School fee should be in range between 0 and 100',
  SessionStatusChanged = 'Session status changed',
  FailedChangeSessionStatus = 'Failed to change session status',
  SessionWasMadePrivate = 'Session is now private',
  SessionPublishedSuccessfully = 'Session published',
  CannotGetAttendeeContactInfo = 'Cannot get contact information for attendee',
  SessionPublished = 'Session status is set to Public',
  FailedToUpdatedSession = 'Failed to update session',
  CannotProcessInactiveEducator = 'Failed to process session. Selected instructor is inactive.',
  FailedToCreatedSession = 'Failed to create session',
  EtleeCreated = 'ETLEE Integration created',
  EtleeUpdated = 'ETLEE Integration updated',
  EtleeCreateFailed = 'Failed to create ETLEE Integration',
  EtleeUpdateFailed = 'Failed to update ETLEE Integration',
  SessionCreated = 'Session created.',
  SessionCreatedButEmail = 'Session created, but failed to send teacher email.',
  SessionUpdatedButTeacherEmail = 'Session updated, but failed to send teacher email.',
  SessionUpdatedButLocationStudentsEmail = 'Session updated, but failed to send location change message to students.',
  SessionUpdatedButLocationStudentsTeacherEmail = 'Session updated, but failed to send location change message to students and teacher.',
  SessionUpdatedAndEmailsSent = 'Session updated. Students and teacher will be notified about changes.',
  SessionUpdatedSuccess = 'Session updated. ',
  CannotUpdateStudent = 'Cannot update student information',
  CustomerNotFound = 'Unable to find customer',
  CannotUpdateCustomer = 'Cannot update customer information',
  FailedUpdateCustomer = 'Failed to update customer',
  StudentDataIsManagedByCustomer = 'Student information is managed by customer directly and therefore cannot be modified',
  CustomerDataIsManagedByCustomer = 'Customer information is managed by customer directly and therefore cannot be modified',
  AddressRequired = 'Address is required',
  UserAddressMinLength = 'Address should be at least 2 symbols',
  CountryRequired = 'Country is required',
  CountryNotFound = 'Country not found',
  CityRequired = 'City is required',
  CityMinLength = 'City should be at least 2 symbols',
  SchoolRequired = 'School is required',
  ZipRequired = 'Zip is required',
  StateRequired = 'State is required',
  StateInvalid = 'State is invalid',
  PostalCodeRequired = 'Postal code is required',
  DateOfBirthRequired = 'Date of birth is required',
  WrongDate = 'Wrong date',
  GenderRequired = 'Gender is required',
  WrongGender = 'Wrong gender value',
  RaceRequired = 'Race is required',
  RelationshipRequired = 'Relationship is required',
  WrongRelationship = 'Wrong relationship value',
  CustodyRequired = 'This field is required',
  WrongAnswerValue = 'Not a valid answer option',
  PostalCodeInvalid = 'Postal code is invalid',
  MapLinkInvalid = 'Map link is invalid',
  SiblingDiscountShouldBePercentage = 'Sibling discount should be percentage',
  TooManyRequests = 'Too many requests',
  ScheduleUpdatedAndEmailsSent = 'Schedule updated. Students and teacher will be notified about changes.',
  ScheduleCreatedAndEmailsSent = 'Schedule created. Students and teacher will be notified about changes.',
  ScheduleCreatedButStudentsEmailsNotSent = 'Schedule created, but emails to students were not sent',
  ScheduleCreatedButTeacherEmailNotSent = 'Schedule created, but email to teacher was not send',
  ScheduleCreatedButStudentsTeacherEmailNotSent = 'Schedule created, but emails to students and teacher was not send',
  ScheduleCreatedButEmail = 'Schedule created, but we failed to send emails',
  ScheduleUpdatedButEmail = 'Schedule updated, but we failed to send emails',
  ScheduleUpdatedButStudentsEmailsNotSent = 'Schedule updated, but emails to students were not sent',
  ScheduleUpdatedButTeacherEmailNotSent = 'Schedule updated, but email to teacher was not send',
  ScheduleUpdatedButStudentsTeacherEmailNotSent = 'Schedule updated, but email to students and teacher was not send',
  FailedToUpdateSchedule = 'Failed to update schedule',
  FailedToCreateSchedule = 'Failed to create schedule',
  SessionCapacityRequired = 'Session capacity is required',
  MinCapacityError = 'Session should have at least one attendee',
  CapacityShouldBeBiggerThanRegistrationsAmount = 'Capacity cannot be less than registrations amount',
  SessionNameRequired = 'Session name is required',
  TeacherRequired = 'Teacher name is required',
  CurrencyRequired = 'Currency is required',
  PreferredPronounRequired = 'Select PreferredPronoun',
  WrongTShirtSizeValue = 'Select T-Shirt size',
  WrongPercentageRange = 'Percentage should be between 0 and 100%',
  EfacityFeeRequired = 'Efacity Fee required',
  WrongFee = 'Fee should be a percentage between 0 and 100',
  WrongTaxPercentage = 'Tax should be a percentage between 0 and 100',
  TaxTypeRequired = 'Tax type required',
  ConnectModelRequired = 'Stripe Connect Model is required',
  PaymentAcceptanceRequired = 'Payment Acceptance Method is required',
  WrongPaymentAcceptanceMethod = 'wrong payment acceptance method',
  SessionNotFount = 'Session not found.',
  SessionCancelled = 'Session is cancelled. No students can be registered.',
  SessionFull = 'Session full. No more students can be registered.',
  CannotAddStudentToSession = 'Cannot add student to session',
  MinLessonsError = 'Session should have at least one lesson',
  InstancesAmountMismatch = 'Instances number and actual instances amount is not the same',
  AddNotePermissionsError = 'Cannot add notes: insufficient permissions',
  GetNotesPermissionError = 'Cannot get notes: insufficient permissions',
  UnableToGetOwners = 'Failed to get owners list',
  RegistrationCancellationReasonRequired = 'Registration cancellation reason is required',
  UnableToSaveAgreement = 'Cannot save agreement',
  CannotFetchAgreements = 'Cannot fetch agreements',
  FailedSendAgreementInvitation = 'Failed to send agreement invitation',
  AgreementInvitationSent = 'Agreement invitation sent',
  FailedGetAgreements = 'Failed to get agreements',
  FailedSignAgreements = 'Failed to sign agreement',
  UnableDeleteAgreement = 'Cannot delete agreement',
  AgreementTypeRequired = 'Agreement type is required',
  AgreementNameRequired = 'Agreement name is required',
  AgreementTextRequired = 'Agreement text is required',
  FailedSignAgreement = 'cannot sign agreement: please, try again later.',
  FailedGetOrganizationAddresses = 'Failed to get addresses',
  FailedGetOrganizationAddress = 'Failed to get address',
  FailedCreateOrganizationAddress = 'Failed to create address',
  FailedDeleteOrganizationAddress = 'Failed to delete address',
  FailedUpdateOrganizationAddress = 'Failed to update address',
  AddressNotFound = 'Address not found',
  GetLocationsFailed = 'Failed to get locations',
  GetLocationByIdFailed = 'Failed to get location by id',
  DeleteLocationFailed = 'Failed to delete location',
  CreateLocationFailed = 'Failed to create location',
  UpdateLocationFailed = 'Failed to update location',
  LocationRequired = 'Location required',
  LocationNameRequired = 'Name required',
  LocationTypeRequired = 'Type required',
  LocationTypeInvalid = 'Location type is incorrect',
  LocationMeetingIdInvalid = 'Meeting id is incorrect',
  LocationMeetingPasswordInvalid = 'Meeting password is incorrect',
  LocationMeetingLinkRequired = 'Meeting link required',
  LocationCapacityInvalid = 'Capacity is incorrect or smaller than 0',
  LocationCapacityRequired = 'Capacity required',
  LocationAddressIdRequired = 'Address required',
  LocationNotFoundInOrganization = 'Location not found in organization',
  AllAgreementsMustBeSigned = 'You must sign all required agreements before payment',
  FailedToCreateOrganization = 'Failed to create organization',
  FailedToUpdateOrganization = 'Failed to update organization',
  FailedToUpdateOrganizationEmailSettings = 'Failed to update email settings',
  OrganizationEmailSettingsUpdated = 'Organization email settings updated',
  FailedToCreateCenter = 'Failed to create center',
  FailedToUpdateCenter = 'Failed to update center',
  CenterDeleted = 'Center deleted',
  FailedToDeleteCenter = 'Failed to delete center',
  FailedToDeleteCenterDueSessions = 'Failed to delete center because it has active sessions. Close active sessions and try again.',
  FailedStripeAccountForOrganization = 'Failed to get Stripe Account for Organization',
  FailedStripeAccountForEfacity = 'Failed to get Stripe Account for Efacity',
  FailedGetStripeAccounts = 'Failed to get Stripe Accounts',
  FailedUpdateStripeAccount = 'Failed to update Stripe Account',
  FailedAddStripeAccount = 'Failed to create Stripe Account',
  FailedGetStripeAccount = 'Failed to get Stripe Account',
  PaymentAccountUpdated = 'Payment Account updated',
  PaymentAccountCreated = 'Payment Account created',
  PaymentAccountDeleted = 'Payment Account deleted',
  FailedDeletePaymentAccount = 'Failed to delete Payment Account',
  FailedDeleteEfacityPaymentAccount = 'You cannot delete default Efacity Payments Account',
  StripeStandardConnected = 'Connected via Stripe Standard Connect',
  FailedStripeStandardConnect = 'Failed to connect via Stripe Standard Connect',
  StripeStandardDisconnected = 'Stripe Standard Connect deauthorized',
  FailedStripeStandardDisconnect = 'Failed to disconnect Stripe Standard Account',
  OrgPaymentAccountUpdated = 'Organization Payment Account updated',
  FailedUpdateOrgStripeAccount = 'Failed to update Organization Stripe Account',
  CannotConnectToOrganization = 'You cannot start Stripe Connect because organization has no Stripe Account',
  AllowedToStandardConnect = 'Allowed only for Stripe Standard Connect model',
  AllowedToExpressConnect = 'Allowed only for Stripe Express Connect model',
  OrganizationTransfersUpdated = 'Organization Transfers updated',
  FailedGetOrganizationPaymentStatus = 'Failed to get organization payment status',
  FailedSetOrganizationPaymentStatus = 'Failed set organization payment status',
  FailedCreateExpressConnectLink = 'Failed to create Express Connect link',
  BankAccountConnected = 'Bank Account connected',
  FailedConnectBankAccount = 'Failed to connect Bank Account',
  FailedUpdateOrganizationTransfer = 'Failed to update Organization Transfer',
  FailedOrganizationsList = 'Failed to get organizations',
  FailedOrganizationLogo = 'Failed load organization log',
  FailedToGetOrganizationSettings = 'Failed to get organization settings',
  FailedToGetOrganizationEmailSettings = 'Failed to get organization Email settings',
  OrganizationNotFound = 'Organization not found',
  OrganizationNotActive = 'Organization not active',
  OrgSettingsButAdminNotification = 'Successfully updated organization settings but failed send administrator notifications',
  OrgSettingsUpdated = 'Successfully updated organization settings',
  OrgNameNotUnique = 'Name in not unique',
  OrgNameRequired = 'Organization name is required',
  OrgTypeRequired = 'Select organization type',
  WrongOrgType = 'Wrong organization type',
  OrganizationRegistrationCreated = 'We received your Organization information and will get in touch.',
  FailedOrganizationRegistration = 'Failed to register your organization. Try later.',
  UserAlreadyAnAdminInOrganization = 'User with provided email already has administrator rights in this organization.',
  FailedAddAdministrator = 'Failed to add administrator',
  FailedDeleteAdministrator = 'Failed to delete administrator',
  FailedOrganizationContactInfo = 'Failed to get organization contact info',
  FailedOrganizationCredits = 'Failed to get credits for organization',
  FailedRefundCredit = 'Failed to refund credit',
  CreditRefundSuccess = 'Credit refunded successfully',
  FailedSetStripeAccount = 'Failed to set stripe account',
  AccountWithoutKeys = 'This account cannot be set for selected Payment Acceptance method = Self because it has no Stripe keys',
  UserExported = 'Users list exported to CSV',
  RegistrationsExported = 'Registrations list exported to CSV',
  FailedExportRegistrations = 'Failed to export registrations list',
  FailedEStartPublicAttendance = 'Failed to go to public attendance page',
  UnsupportedFileType = 'Unsupported file type',
  FailedGetReceiptPdf = 'Failed to get Payment Receipt PDF',
  FailedGetInvoicePdf = 'Failed to get Invoice PDF',
  InvalidPhoneNumber = 'Phone number is invalid',
  URLRequired = 'Valid URL required',
  FailedGetUserCredits = 'Failed to get credits for user',
  FailedGetActivityCertificates = 'Failed to get activity certificates',
  FailedGetNonCertificatedInstructors = 'Failed to get non-certificates instructors list',
  FailedAssignCertificate = 'Failed to assign activity certificate to user',
  InstructorAlreadyHasCertificate = 'Instructor already has certificate on this activity',
  CertificateAdded = 'Activity certificate granted to instructor',
  FailedGetUserCertificates = 'Failed to get certificates',
  JobNotFound = 'Job Posting not found',
  FailedToCreateJob = 'Failed to create Job',
  FailedToUpdateJob = 'Failed to update Job',
  FailedGetJobPostings = 'Failed to get Job Posting',
  JobDeleted = 'Job deleted',
  FailedToDeleteJob = 'Failed to delete Job',
  FailedCreateJobApplication = 'Failed to create Job Application',
  JobApplicationCreated = 'We received your Job Application information and will get in touch.',
  FailedGetReviews = 'Failed to get Reviews',
  FailedUpdateReviewStatus = 'Failed to update review status',
  ReviewRatingMustBeNumber = 'Rating must be a positive number from 1 to 5',
  FailedAddReviewSessionNotFinished = 'Cannot add Review for a session that is not finished yet',
  RegistrationAlreadyHasReview = 'A review for this registration already exists',
  RegistrationNotActive = 'This registration is not active',
  FailedConfirmRegistrationCanBeReviewed = 'Failed to confirm the session can be reviewed',
  FailedGetRegistrationToReview = 'Failed to get Registration to add a review for',
  FailedAddReview = 'Failed to add a review',
  ValueMustBeNumber = 'Must be a number',
  FailedGetEnrollmentApplications = 'Failed to get enrollment applications',
  FailedGetEnrollmentApplication = 'Failed to get enrollment application',
  FailedProcessEnrollmentStatus = 'Failed to process enrollment status',
  FailedEnrollment = 'Failed to enroll',
  EnrollmentUpdated = 'Enrollment updated',
  FailedUpdateEnrollment = 'Failed to update enrollment',
  EnrollmentApprovedButEmail = 'Enrollment application approved but we failed to send email to customer.',
  FailedApproveEnrollment = 'Failed to approve enrollment application',
  EnrollmentDeleted = 'Enrollment application deleted',
  FailedDeleteEnrollment = 'Failed to delete enrollment application',
  EnrollmentNotAllowed = 'Enrollment not allowed',
  RequestChangesCommentRequired = 'Please provide a message for the customer',
  FailedRequestChangesToEnrollment = 'Failed to request changes to enrollment application',
  EnrollmentChangesRequestedButEmail = 'Requested changes to the enrollment application but we failed to send email to customer.',
  NoInvoiceForRegistration = 'This registration has no invoice. Please, contact session provider!',
  NoInvoiceForPurchase = 'This purchase has no invoice. Please, contact the seller!'
}

export const getPerPageLimitMessage = (maxAllowedAmount: number) => {
  return `${Messages.PerPageLimitIsTooBig} ${maxAllowedAmount}`;
};

export const getFieldIsRequiredMessage = (fieldName: string) => {
  return `${fieldName} ${Messages.FieldRequired}`;
};
